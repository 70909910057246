// Core
import React, { useEffect } from "react"

// Layout
import { EmptyLayout } from "../layouts"

// Components
import { Seo } from "../components"

// Icons
import { BsArrowRightShort } from "react-icons/bs"
import BurgerKingIcon from "../assets/icons/brand-burger-king.inline.svg"
import PopeyesIcon from "../assets/icons/brand-popeyes.inline.svg"
import LogoIcon from "../assets/icons/logo.inline.svg"

const GiftsPage = () => {
  useEffect(() => {
    document.body.classList.add("no-scroll")
  }, [])

  return (
    <EmptyLayout>
      <Seo title="Outloud" />
      <section className="landing">
        <div className="landing__logo">
          <LogoIcon className="landing__logo--icon" />
          <span className="landing__logo--text">OUTLOUD</span>
        </div>

        <div className="landing__row">
          <h1 className="landing__title">Outloud Gifts</h1>
          <h2 className="landing__subtitle">
            Recognize and reward your best performing team members.
          </h2>
          <p className="landing__description">
            We give team leaders a tool to send gifts to best performers at
            their stores.
          </p>
          <a
            href="https://calendly.com/sasha-outloud/outloud-incentives"
            target="_blank"
            rel="noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <button className="landing__button">
              Get Started{" "}
              <BsArrowRightShort className="landing__button--arrow" />
            </button>
          </a>
        </div>

        <section className="home-brands">
          <p className="home-brands__title">Trusted by leading brands:</p>
          <ul className="home-brands__list">
            <li className="home-brands__item">
              <BurgerKingIcon className="home-brands__icon--burger" />
            </li>
            <li className="home-brands__item">
              <PopeyesIcon className="home-brands__icon--popeyes" />
            </li>
          </ul>
        </section>
      </section>
    </EmptyLayout>
  )
}

export default GiftsPage
